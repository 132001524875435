
.horzPa {
    display: flex; flex-direction: row;
}

.listPa {
    background-color: #555; padding-right: 1em;
}

.vertPa {
    flex-grow: 1; background-color: white;
}

.qrLine {
    white-space: pre;
    font-family: monospace;
}

.qrPa {
    display: flex; flex-direction: column; justify-content: center; align-items: center; flex-grow: 1; background-color: white; align-self: stretch;
}
.qrPa2 {
    flex-grow: 1;
}