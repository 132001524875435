.secTitle {
    font-weight: bold;
    color: #999;
    margin-right: 22;
}
.form-horizontal {
    display: flex;
    align-items: center;
    margin: 22;
}
.form-control {
    margin: 22;
    flex-grow: 1;
    height: 55;
}